import moment from 'moment'

export const filterTimelineEvents = (
  events,
  { type, editedBy, forEmployee, selectedCompany },
) => {
  let filteredEvents = events

  if (type != null) {
    filteredEvents = filteredEvents.filter((event) => event.type === type)
  }

  if (editedBy != null) {
    filteredEvents = filteredEvents.filter((event) =>
      editedBy === -1 ? event.createdBy === null : event.createdBy === editedBy,
    )
  }

  if (forEmployee != null) {
    filteredEvents = filteredEvents.filter(
      (event) => event.employeeId === forEmployee,
    )
  }

  if (selectedCompany != null) {
    filteredEvents = filteredEvents.filter(
      (event) => event.vlCompany === selectedCompany,
    )
  }

  return filteredEvents
}

const nameAscendingComparator = (e1, e2) => {
  const lastNameComparison = e1.lastName.localeCompare(e2.lastName)
  return lastNameComparison === 0
    ? e1.firstName.localeCompare(e2.firstName)
    : lastNameComparison
}

const nameDescendingComparator = (e1, e2) => {
  const lastNameComparison = e2.lastName.localeCompare(e1.lastName)
  return lastNameComparison === 0
    ? e2.firstName.localeCompare(e1.firstName)
    : lastNameComparison
}

const startDescendingComparator = (e1, e2) => {
  const start1 = moment(e1.startingDate)
  const start2 = moment(e2.startingDate)
  return -start1.diff(start2, 'days')
}

const startAscendingComparator = (e1, e2) => {
  const start1 = moment(e1.startingDate)
  const start2 = moment(e2.startingDate)
  return start1.diff(start2, 'days')
}

export const comparators = {
  nameAscending: nameAscendingComparator,
  nameDescending: nameDescendingComparator,
  startAscending: startAscendingComparator,
  startDescending: startDescendingComparator,
}

export const extractContractAndSubcontracts = (employee) => [
  {
    ...employee,
    isSubcontract: false,
    indexId: `${employee.id}_${employee.contractId}`,
  },
  ...employee.subcontracts.map((subcontract) => ({
    ...employee,
    equipmentBonus: null,
    shareBonus: null,
    sparringShareBonus: null,
    legacyShares: null,
    rollingShares: null,
    note: null,
    level: null,
    ...subcontract,
    isSubcontract: true,
    indexId: `${employee.id}_${subcontract.contractId}`,
    originalParentData: employee,
  })),
]
