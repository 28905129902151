import { saveAs } from 'file-saver'
import { get, isString, isNumber, flatten } from 'lodash'
import moment from 'moment'

import { apiRequest, handleUncaughtError } from './network'
import { dateFormat, monthFormat } from './ui'
import { getFullNameReversed } from './fields'
import { filterTimelineEvents } from './helpers'
import { createXLSX, separator } from './export'

export const exportOptions = [
  { key: 'csv', label: 'CSV', tooltip: 'Download current table view as CSV' },
  {
    key: 'xlsx',
    label: 'XLSX',
    tooltip: 'Download current table view as XLSX',
  },
  {
    key: 'monthly',
    label: 'Monthly',
    tooltip:
      'Download XLSX with employees active at least 1 day in current month.',
  },
  {
    key: 'yearly',
    label: 'Yearly',
    tooltip:
      'Download XLSX with employees active at least 1 day in current year.',
  },
]

function formatCompanyFields(companyFields, company) {
  return companyFields.map(({ name, options, resolve, exportValue }) => {
    let value
    if (resolve) {
      value = resolve(company, { exportable: true })
    } else if (exportValue) {
      value = exportValue(company)
    } else {
      value = company[name]
      if (options) {
        value = get(options, [value, 'label'], value)
      }
    }
    return value
  })
}

async function getSpreadSheetRow(
  employee,
  csv = false,
  { employeeFields, companyFields },
) {
  const formattedEmployeeFields = employeeFields.map(
    ({ name, options, resolve, exportValue }) => {
      let value

      if (resolve) {
        value = resolve(employee, {
          date: moment(employee.validFrom),
          exportable: true,
        })
      } else if (exportValue) {
        value = exportValue(employee)
      } else {
        value = employee[name]
        if (options) {
          value = get(options, [value, 'label'], value)
        }
      }

      if (csv) {
        value = isNumber(value) || isString(value) ? value.toString() : ''
        if (value.includes(separator) || value.includes('"')) {
          value = `"${value.replace(/"/g, '""')}"`
        }
      } else {
        if (name === 'salary' || name === 'fteEquivalent') {
          value = parseFloat(value)
        }
        value =
          (!isNaN(value) && isNumber(value)) || isString(value) ? value : ''
      }
      return value
    },
  )

  if (employee.companyId && companyFields) {
    const company =
      employee.company || (await apiRequest(`companies/${employee.companyId}`))
    const formattedCompanyFields = formatCompanyFields(companyFields, company)
    return formattedEmployeeFields.concat(formattedCompanyFields)
  } else {
    return formattedEmployeeFields
  }
}

function getSpreadSheetHeaders({ employeeFields = [], companyFields = [] }) {
  return [...employeeFields, ...companyFields].map(({ label }) => label)
}

function getImportFileHeaders({ employeeFields = [], companyFields = [] }) {
  return [...employeeFields, ...companyFields].map(({ name }) => name)
}

function getTimelineSheetRow(event, eventFields, companies) {
  const { resolve, exportValue, options, label, units } = event.field
  return eventFields.map(({ name }) => {
    if (name === 'property') {
      return label
    }
    const fieldValue = event[name]
    if (name === 'from' || name === 'to') {
      if (fieldValue != null) {
        let value
        if (resolve) {
          try {
            value = resolve(
              { [event.property]: fieldValue },
              { exportable: true },
            )
          } catch {
            value = fieldValue
            // eslint-disable-next-line no-console
            console.warn(`Cannot resolve value '${value}' of field ${name}`)
          }
        } else if (exportValue) {
          value = exportValue({ [event.property]: fieldValue })
        } else if (units) {
          value = `${fieldValue} ${units({ [event.property]: fieldValue })}`
        } else {
          value = fieldValue
          if (options) {
            value = get(options, [value, 'label'], value)
          } else if (event.property === 'companyId') {
            value = get(companies, [value, 'legalName'])
          }
        }
        return value
      } else {
        return ''
      }
    } else {
      return fieldValue
    }
  })
}

function generateTimelineXLSX(events, datePart, eventFields, companies) {
  const rows = events.map((event) =>
    getTimelineSheetRow(event, eventFields, companies),
  )
  rows.unshift(eventFields.map(({ label }) => label))
  createXLSX(rows, 'timeline', datePart)
}

export async function generateXLSX(employees, datePart, fields) {
  const rows = await Promise.all(
    employees.map((employee) => getSpreadSheetRow(employee, false, fields)),
  )
  rows.unshift(getSpreadSheetHeaders(fields))
  createXLSX(rows, 'employees', datePart)
}

export async function generateCSVWithHeaders(
  employees,
  datePart,
  fields,
  getHeadersFn,
) {
  let out = '\uFEFF'
  const headers = getHeadersFn(fields)
  out += `${headers.join(separator)}\r\n`

  for (const employee of employees) {
    const rowFields = await getSpreadSheetRow(employee, true, fields)
    out += `${rowFields.join(separator)}\r\n`
  }

  const fileName = `employees-${datePart}.csv`
  saveAs(new Blob([out], { type: 'text/csv' }), fileName)
}

export async function generateCSV(employees, datePart, fields) {
  await generateCSVWithHeaders(
    employees,
    datePart,
    fields,
    getSpreadSheetHeaders,
  )
}

export async function generateCSVImportTemplate(employees, datePart, fields) {
  await generateCSVWithHeaders(
    employees,
    datePart,
    fields,
    getImportFileHeaders,
  )
}

export function getMonthlyExportXLSX(date, addNotification, fields) {
  const firstDay = moment.utc(date).startOf('month')
  const options = {
    query: { date: firstDay.format(dateFormat) },
  }
  return apiRequest('monthlyExport', options)
    .then((employees) =>
      generateXLSX(employees, moment.utc(date).format(monthFormat), fields),
    )
    .catch((err) => handleUncaughtError(err, addNotification))
}

export function getYearlyExportXLSX(date, addNotification, fields) {
  const firstDay = moment.utc(date).startOf('year')
  const options = {
    query: { date: firstDay.format(dateFormat) },
  }
  return apiRequest('yearlyExport', options)
    .then((employees) =>
      generateXLSX(employees, moment.utc(date).format('YYYY'), fields),
    )
    .catch((err) => handleUncaughtError(err, addNotification))
}

export const getTimelineAsXLSX = (
  employees,
  companies,
  date,
  editedBy,
  forEmployee,
  selectedCompany,
  { eventFields, employeeFields },
  addNotification,
) => {
  const options = {
    query: { date },
  }
  return apiRequest('employees/timeline', options)
    .then((events) => {
      // manual shares are not supported
      const filteredEvents = filterTimelineEvents(events, {
        type: 'contract',
        editedBy,
        forEmployee,
        selectedCompany,
      })
      const parsedEvents = filteredEvents.map((event) =>
        employeeFields
          .map((field) => {
            const change = event.changes[field.name]
            if (!change) {
              return null
            }
            return {
              field,
              createdBy:
                event.createdBy != null
                  ? getFullNameReversed(employees[event.createdBy])
                  : 'vacuum',
              name: getFullNameReversed(event),
              vacuumId: event.vacuumId,
              createdAt: moment.utc(event.createdAt).format(dateFormat),
              validFrom: moment.utc(event.validFrom).format(dateFormat),
              property: field.name,
              from: change.from,
              to: change.to,
              reason: event.comment,
              deletedAt:
                event.deletedAt != null
                  ? moment.utc(event.deletedAt).format(dateFormat)
                  : '',
              deletedBy:
                event.deletedBy != null
                  ? getFullNameReversed(employees[event.deletedBy])
                  : '',
            }
          })
          .filter((x) => x),
      )
      generateTimelineXLSX(
        flatten(parsedEvents),
        moment.utc(date).format(monthFormat),
        eventFields,
        companies,
      )
    })
    .catch((err) => handleUncaughtError(err, addNotification))
}
