import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  ButtonToolbar,
  Glyphicon,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap'
import classnames from 'classnames'
import moment from 'moment'
import { get } from 'lodash'
import { legalForms } from './common/enums'
import { getConfig, dateFormatHuman } from './utils'
import styles from './Employees.module.scss'

export default class Employee extends Component {
  static propTypes = {
    rowNumber: PropTypes.number.isRequired,
    employee: PropTypes.object.isRequired,
    date: PropTypes.object.isRequired,
    employeeFields: PropTypes.array.isRequired,
    onClick: PropTypes.func.isRequired,
    showTimeline: PropTypes.func.isRequired,
    showPrefilledEditor: PropTypes.func.isRequired,
    showDetails: PropTypes.string.isRequired,
    showManualShareEditor: PropTypes.func.isRequired,
  }

  handleRowClick = () => {
    this.props.onClick(this.props.employee)
  }

  handleTimelineClick = (event) => {
    event.stopPropagation()
    this.props.showTimeline(this.props.employee)
  }

  handleEditClick = (event) => {
    const { showPrefilledEditor, employee } = this.props
    event.stopPropagation()
    showPrefilledEditor(
      employee.isSubcontract ? employee.originalParentData : employee,
    )
  }

  render() {
    const {
      employee,
      employeeFields,
      rowNumber,
      date,
      showDetails,
      showManualShareEditor,
    } = this.props
    const { nextContract } = employee
    const nextValidFrom = moment.utc(nextContract.validFrom)
    const nextContractDate = nextValidFrom.isValid()
      ? nextValidFrom.format(dateFormatHuman)
      : null
    const isLegalEntity = !get(legalForms, [employee.legalForm, 'isEmployment'])
    const expiredContract =
      moment(employee.contractExpiration).isBefore(date) && !isLegalEntity
    const noEmploymentData = showDetails === 'employees' && isLegalEntity

    let colorStyle = ''
    if (
      employee.nextContract.validFrom &&
      employee.isActive !== employee.nextContract.isActive
    ) {
      colorStyle = styles.statusBarFutureChange
    } else if (expiredContract) {
      colorStyle = styles.statusBarExpiredContract
    } else if (!employee.isActive || noEmploymentData) {
      colorStyle = styles.statusBarInactive
    } else {
      colorStyle = styles.statusBarActive
    }

    const component = (
      <div
        className={classnames([
          styles.employeesBodyRow,
          noEmploymentData && styles.noEmploymentData,
        ])}
        onClick={this.handleRowClick}
      >
        <div className={classnames([styles.statusBar, colorStyle])} />
        {employeeFields.map(
          ({
            name,
            options,
            resolve,
            render,
            formControl,
            units,
            tooltipText,
          }) => {
            let value = employee[name],
              nextValue = nextContract[name]
            const nextDiffers = nextValue != null && nextValue !== value
            const numberClass =
              formControl && formControl.type === 'number' ? styles.number : ''
            const futureClass = nextDiffers ? styles.future : ''

            if (render) {
              value = render(employee)
            } else if (resolve) {
              value = resolve(employee, { date })
            } else if (options) {
              value = get(options, [value, 'label'], value)
            }

            if (name === 'rowNumber') {
              value = rowNumber
            }

            if (name === 'validFrom') {
              nextValue = nextContractDate
            } else if (options) {
              nextValue = get(options, [nextValue, 'label'], nextValue)
            }

            let fieldValue = (
              <div
                key={name}
                className={classnames([
                  numberClass,
                  futureClass,
                  styles.employeesCell,
                  styles[`box-${name}`],
                ])}
              >
                <div className={styles.employeesCellValue}>
                  {value}
                  {value != null && units && !render && (
                    <span>&nbsp;{units(employee)}</span>
                  )}
                </div>
              </div>
            )

            if (nextDiffers) {
              const tooltip = (
                <Tooltip id="next-value">
                  {name === 'validFrom'
                    ? `The next contract will be valid from ${nextContractDate}`
                    : `Will be ${nextValue}${
                        units ? `\xA0${units(nextContract)}` : ''
                      } from ${nextContractDate}`}
                </Tooltip>
              )
              fieldValue = (
                <OverlayTrigger key={name} placement="right" overlay={tooltip}>
                  {fieldValue}
                </OverlayTrigger>
              )
            } else if (tooltipText) {
              const tooltip = (
                <Tooltip id="shares">{tooltipText(employee)}</Tooltip>
              )
              fieldValue = (
                <OverlayTrigger key={name} placement="top" overlay={tooltip}>
                  {fieldValue}
                </OverlayTrigger>
              )
            }

            return fieldValue
          },
        )}
        <div
          className={classnames([
            styles.employeesCell,
            styles.employeesButtons,
          ])}
        >
          <ButtonToolbar className="pull-right">
            <Button
              data-test="timelineEmployee"
              bsSize="small"
              title="Timeline"
              onClick={this.handleTimelineClick}
            >
              <Glyphicon glyph="time" />
            </Button>
            {getConfig().writeAccess && employee.writeAccess && (
              <Button
                data-test="editEmployee"
                bsSize="small"
                bsStyle="primary"
                title="Edit"
                onClick={this.handleEditClick}
              >
                <Glyphicon glyph="pencil" />
              </Button>
            )}
          </ButtonToolbar>
        </div>
      </div>
    )

    let overlay
    if (expiredContract) {
      overlay = <Tooltip id="expired">Expired contract</Tooltip>
    } else if (noEmploymentData) {
      overlay = <Tooltip id="noEmploymentData">Not an employee</Tooltip>
    }

    return overlay ? (
      <OverlayTrigger placement="bottom" overlay={overlay}>
        {component}
      </OverlayTrigger>
    ) : (
      component
    )
  }
}
