import moment from 'moment'
import { stringify } from 'qs'
import { omit } from 'lodash'
import url from 'url'
import { dateFormat } from './ui'
import { pushNotification, notifications } from './notifications'

export const sessionExpired = 'SESSION_EXPIRED'

const checkRequestOk = (response) =>
  !response.ok && response.status !== 401
    ? Promise.reject(response)
    : Promise.resolve(response)

const checkLoginStatus = (response) => {
  if (response.status !== 401) {
    return Promise.resolve(response)
  } else {
    localStorage.setItem(sessionExpired, true)
    return Promise.reject(sessionExpired)
  }
}

export const handleUncaughtError = (err, addNotification) => {
  console.error(err) // eslint-disable-line no-console
  pushNotification(notifications.networkError, addNotification)
}

export const apiRequest = (apiPath, userOptions = {}) => {
  const defaultOptions = {
    method: 'GET',
    credentials: 'include',
    headers: {},
  }

  const options = omit({ ...defaultOptions, ...userOptions }, [
    'query',
    'body',
    'ignoreAuthError',
  ])

  if (userOptions.body) {
    options.body = JSON.stringify(userOptions.body)
    options.headers['Content-Type'] = 'application/json; charset=utf-8'
  }

  if (options.method !== defaultOptions.method) {
    options.cache = 'no-cache'
  }

  let apiUrl = url.resolve('/api/', apiPath)
  if (userOptions.query) {
    apiUrl += `?${stringify(userOptions.query)}`
  }

  return fetch(apiUrl, options)
    .then(checkRequestOk)
    .then(checkLoginStatus)
    .then((response) => response.json())
    .catch((err) => {
      if (err === sessionExpired) {
        if (userOptions.ignoreAuthError) {
          return Promise.reject(err)
        } else {
          return window.location.reload()
        }
      } else {
        return Promise.reject(err)
      }
    })
}

export const getFutureContracts = (employeeId, validAfter) => {
  validAfter = moment.isMoment(validAfter)
    ? validAfter.format(dateFormat)
    : validAfter
  const options = {
    query: { validAfter },
  }
  return apiRequest(`employees/${employeeId}/futureContracts`, options)
}

export const getContractsAll = (employeeId, date) => {
  date = moment.isMoment(date) ? date.format(dateFormat) : date
  const options = {
    query: { date },
  }

  return apiRequest(`employees/${employeeId}/contracts`, options).then(
    (contracts) => {
      for (let i = contracts.length - 1; i > 0; i--) {
        contracts[i].validTo = moment(contracts[i - 1].validFrom).subtract(
          1,
          'days',
        )
      }
      return contracts
    },
  )
}

let currenciesRates = {}

export const getCurrencyRate = (currency, date) => {
  let month = date.format('YYYY-MM')
  const currentMonth = moment().format('YYYY-MM')
  if (month > currentMonth) {
    month = currentMonth
  }

  const currencyData = currenciesRates[currency]
  if (!currencyData) {
    return undefined
  }

  let monthData = currencyData[month]
  let distanceInMonths = 0
  while (!monthData) {
    distanceInMonths++
    monthData =
      currencyData[
        moment(month).add(distanceInMonths, 'months').format('YYYY-MM')
      ] ||
      currencyData[
        moment(month).subtract(distanceInMonths, 'months').format('YYYY-MM')
      ]
  }

  return monthData.avg_rate
}

export const fetchCurrencyRates = async () => {
  const response = await apiRequest('forex')
  currenciesRates = response.rates
  return currenciesRates
}
