export const eventFields = [
  {
    name: 'createdBy',
    label: 'Editor',
  },
  {
    name: 'name',
    label: 'Employee',
  },
  {
    name: 'vacuumId',
    label: 'Vacuum ID',
  },
  {
    name: 'createdAt',
    label: 'Created At',
  },
  {
    name: 'validFrom',
    label: 'Valid From',
  },
  {
    name: 'property',
    label: 'Property',
  },
  {
    name: 'from',
    label: 'From',
  },
  {
    name: 'to',
    label: 'To',
  },
  {
    name: 'reason',
    label: 'Reason',
  },
  {
    name: 'deletedAt',
    label: 'Deleted At',
  },
  {
    name: 'deletedBy',
    label: 'Deleted By',
  },
]
