import React from 'react'
import { Button, Navbar } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import pathToRegexp from 'path-to-regexp'
import classNames from 'classnames'
import { getConfig } from '../utils'
import Logo from './Logo'
import styles from './Navbar.module.scss'

const isEmployeesActive = (match, location) =>
  match || location.pathname === '/'

const toJiraProfilePage = (...args) =>
  pathToRegexp.compile(getConfig().jiraProfilePage)(...args)

export default () => {
  const { avatarUrl, name, username } = getConfig()

  return (
    <Navbar className={styles.noTopBorder}>
      <Navbar.Header>
        <Navbar.Brand>
          <Logo />
        </Navbar.Brand>
        <Navbar.Toggle className={styles.navbarToggle} />
      </Navbar.Header>
      <Navbar.Collapse>
        {getConfig().readAccess && (
          <ul className={classNames(styles.links, 'nav navbar-nav')}>
            <li>
              <NavLink
                to="/employees"
                className={styles.link}
                isActive={isEmployeesActive}
              >
                Employees
              </NavLink>
            </li>
            <li>
              <NavLink to="/companies" className={styles.link}>
                Companies
              </NavLink>
            </li>
            <li className="visible-xs-block">
              <a
                href={toJiraProfilePage({ vacuumId: username })}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.link}
              >
                {unescape(name)}
              </a>
            </li>
            <li className="visible-xs-block">
              <a href="/auth/logout" className={styles.link}>
                Logout
              </a>
            </li>
          </ul>
        )}
        <div
          className={classNames(
            styles.user,
            'navbar-text',
            'navbar-right',
            'hidden-xs',
          )}
        >
          <a
            className={styles.link}
            href={toJiraProfilePage({ vacuumId: username })}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className={styles.username}>{unescape(name)}</span>
            <img
              src={avatarUrl}
              className={styles.avatar}
              alt={unescape(name)}
            />
          </a>
          <Button
            data-test="logout"
            href="/auth/logout"
            bsStyle="default"
            className="btn-transparent"
          >
            Logout
          </Button>
        </div>
      </Navbar.Collapse>
    </Navbar>
  )
}
