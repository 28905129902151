import React, { Component } from 'react'
import {
  Grid,
  Row,
  Col,
  Button,
  ButtonGroup,
  FormControl,
  InputGroup,
  Glyphicon,
  Clearfix,
  DropdownButton,
  MenuItem,
} from 'react-bootstrap'
import ScrollArea from 'react-scrollbar'
import qs from 'qs'
import { remove as removeDiacritics } from 'diacritics'
import classnames from 'classnames'
import moment from 'moment'
import { get, pickBy, has, fromPairs, isEqual } from 'lodash'
import update from 'immutability-helper'
import { statuses, vlCompanies } from './common/enums'
import {
  employeeFields,
  employeeFieldNames,
  employeeFieldsViewableInTable,
  employeeFieldsPersonalDetails,
  rowNumberAndName,
  employmentDetailsFields,
  employeeFieldsExportable,
  employeeFieldsMonthlyExportable,
} from './employeeFields'
import {
  companyFieldsExportable,
  companyFieldsForEmployeeExport,
} from './companyFields'
import EmployeeEditor from './EmployeeEditor'
import SalaryContractsInfo from './SalaryContractsInfo'
import ManualShareEditor from './ManualShareEditor'
import ColumnDropdown from './components/ColumnDropdown'
import ColumnRangeDropdown from './components/ColumnRangeDropdown'
import ColumnDateRangeDropdown from './components/ColumnDateRangeDropdown'
import {
  dateFormat,
  monthFormat,
  getFullName,
  exportOptions,
  generateCSV,
  generateXLSX,
  getMonthlyExportXLSX,
  getYearlyExportXLSX,
  isFloat,
  apiRequest,
  handleUncaughtError,
  getConfig,
  comparators,
  getFiltersFromUrl,
  filtersToUrl,
  isEmpty,
  isChanged,
  extractContractAndSubcontracts,
} from './utils'
import NotificationContext from 'utils/context/NotificationContext'
import DateTimeToday from './components/DateTimeToday'
import Loading from './components/Loading'
import TableTitle from './components/TableTitle'
import BulkUpdateModal from 'components/BulkUpdateModal'
import Timeline from './Timeline'
import HistoricalData from './components/HistoricalData'
import Employee from './Employee'
import styles from './Employees.module.scss'
import BulkImportModal from './components/BulkImportModal'

const getEmployeeFields = (showDetails) => {
  if (showDetails === 'employees') {
    return [...rowNumberAndName, ...employmentDetailsFields]
  } else if (showDetails === 'personal') {
    return employeeFieldsPersonalDetails
  } else {
    return employeeFieldsViewableInTable
  }
}

const isFieldDefaultValue = (name, value) => {
  const defaultValue = employeeFieldNames[name].filterDefaultValue
  return defaultValue !== undefined
    ? isEqual(value, defaultValue)
    : !value?.length
}

export default class Employees extends Component {
  static contextType = NotificationContext

  constructor() {
    super()

    const rangeFilters = {}
    const dateRangeFilters = {}

    employeeFields.forEach(({ name, rangeFilter, dateRangeFilter }) => {
      if (rangeFilter) {
        rangeFilters[name] = {}
      } else if (dateRangeFilter) {
        dateRangeFilters[name] = {}
      }
    })

    this.state = {
      editor: {
        show: false,
        employee: {},
        updating: false,
      },
      date: this.getToday(),
      active: true,
      search: '',
      filters: {},
      rangeFilters,
      dateRangeFilters,
      filteredEmployees: [],
      salary: {},
      showBulkImportModal: false,
      showBulkUpdateModal: false,
      showHistoricalData: false,
      timeline: {},
      manualShareEditor: { show: false, employeeId: null },
      showDetails: 'contracts',
      comparator: comparators.nameAscending,
    }
  }

  componentDidMount() {
    this.updateFiltersAndTimelines()
    this.polling = window.setInterval(this.getEmployees, getConfig().polling)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search) {
      this.updateFiltersAndTimelines()
    }
  }

  componentWillUnmount() {
    window.clearInterval(this.polling)
  }

  getFiltersWithDefaults = () =>
    fromPairs(
      employeeFields.map((f) => [
        f.name,
        this.state.filters[f.name] ?? f.filterDefaultValue ?? [],
      ]),
    )

  updateUrlEmployee = () => {
    const { salary } = this.state
    const dataType =
      localStorage.contractTimeline === 'true' ? 'contracts' : 'salaries'
    const query = pickBy(
      {
        show: dataType,
        contractTimelineDate: salary.contractTimelineDate
          ? salary.contractTimelineDate.format(dateFormat)
          : null,
      },
      (prop) => prop,
    )
    const queryString = qs.stringify(query)
    this.props.history.push(`${this.props.location.pathname}?${queryString}`)
  }

  updateUrl = () => {
    const {
      search,
      filters,
      rangeFilters,
      dateRangeFilters,
      date,
      active,
      timeline,
      salary,
      showDetails,
    } = this.state
    let timelineUrl = null
    let salaryUrl = null

    if (timeline.employeeId) {
      timelineUrl = { timelineFor: timeline.employeeId }
    } else if (timeline.show) {
      timelineUrl = {
        monthlyTimeline: true,
        timelineMonth: timeline.month
          ? timeline.month.format(monthFormat)
          : null,
        editedBy: timeline.editedBy ? timeline.editedBy : null,
        forEmployee: timeline.forEmployee ? timeline.forEmployee : null,
        selectedCompany: timeline.selectedCompany || null,
      }
    } else if (salary.employeeId) {
      const urlType =
        localStorage.contractTimeline === 'true' ? 'contractFor' : 'salaryFor'
      salaryUrl = { [urlType]: salary.employeeId }
      if (salary.contractTimelineDate) {
        salaryUrl.contractTimelineDate =
          salary.contractTimelineDate.format(dateFormat)
      }
    }

    const formattedRangeFilters = {}
    Object.entries(rangeFilters).forEach(([field, rangeFilter]) => {
      if (rangeFilter.changed) {
        formattedRangeFilters[
          field
        ] = `${rangeFilter.currentMin}~${rangeFilter.currentMax}`
      }
    })

    const formattedDateRangeFilters = {}
    Object.entries(dateRangeFilters).forEach(([field, dateRangeFilter]) => {
      if (dateRangeFilter.changed) {
        formattedDateRangeFilters[field] = `${moment
          .utc(dateRangeFilter.currentMinDate)
          .format(dateFormat)}~${moment
          .utc(dateRangeFilter.currentMaxDate)
          .format(dateFormat)}`
      }
    })

    const queryParams = {
      inactive: !active ? 1 : null,
      details: showDetails,
      search: search || null,
      ...filtersToUrl(filters),
      ...timelineUrl,
      ...salaryUrl,
      ...formattedRangeFilters,
      ...formattedDateRangeFilters,
    }

    if (!date.isSame(this.getToday())) {
      queryParams.date = date.format(dateFormat)
    }

    const query = pickBy(queryParams, (prop) => prop != null)

    const queryString = qs.stringify(query, { addQueryPrefix: true })
    if (queryString !== this.props.location.search) {
      this.props.history.push(`${this.props.location.pathname}${queryString}`)
    }
  }

  updateFiltersAndTimelines() {
    const { readAccess } = getConfig()
    const query = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    })
    const queryDate = moment.utc(query.date, dateFormat)
    const date = queryDate.isValid() ? queryDate : this.getToday()

    const showTimeline = has(query, 'timelineFor')
    const showMonthlyTimeline = has(query, 'monthlyTimeline')
    const showContracts =
      has(query, 'contractFor') || (!readAccess && query.show === 'contracts')
    const showSalary =
      has(query, 'salaryFor') || (!readAccess && query.show === 'salaries')

    let salaryEmployeeId = null,
      timelineEmployeeId = null,
      timelineMonth = null,
      editedBy = null,
      forEmployee = null,
      selectedCompany = null,
      contractTimelineDate = null
    if (showTimeline) {
      timelineEmployeeId = parseInt(query.timelineFor, 10)
    } else if (showContracts) {
      salaryEmployeeId = parseInt(query.contractFor, 10)
      localStorage.setItem('contractTimeline', true)
    } else if (showSalary) {
      salaryEmployeeId = parseInt(query.salaryFor, 10)
      localStorage.setItem('contractTimeline', false)
    }

    if (has(query, 'timelineMonth')) {
      timelineMonth = moment(query.timelineMonth, monthFormat)
    }

    if (has(query, 'contractTimelineDate')) {
      contractTimelineDate = moment(query.contractTimelineDate, dateFormat)
    }

    if (has(query, 'editedBy')) {
      editedBy = Number(query.editedBy)
    }

    if (has(query, 'forEmployee')) {
      forEmployee = Number(query.forEmployee)
    }

    if (has(query, 'selectedCompany')) {
      selectedCompany = query.selectedCompany
    }

    const urlRangeFilters = this.getRangeFilters(query)
    let { rangeFilters } = this.state

    // if range filter url param was removed the filter has to be reset
    Object.entries(rangeFilters).forEach(([field, rangeFilter]) => {
      const { minValue, maxValue } = rangeFilter
      let changes
      if (!has(urlRangeFilters, field)) {
        changes = {
          changed: false,
          currentMin: minValue,
          currentMax: maxValue,
        }
      } else {
        const { currentMin, currentMax } = urlRangeFilters[field]
        const changed = currentMin !== minValue || currentMax !== maxValue
        changes = {
          changed,
          currentMin,
          currentMax,
        }
      }
      rangeFilters = update(rangeFilters, { [field]: { $merge: changes } })
    })

    const urlDateRangeFilters = this.getDateRangeFilters(query)
    let { dateRangeFilters } = this.state

    // if date range filter url param was removed the filter has to be reset
    Object.entries(dateRangeFilters).forEach(([field, dateRangeFilter]) => {
      const { minDateValue, maxDateValue } = dateRangeFilter
      let changes
      if (!has(urlDateRangeFilters, field)) {
        changes = {
          changed: false,
          currentMinDate: minDateValue,
          currentMaxDate: maxDateValue,
        }
      } else {
        const { currentMinDate, currentMaxDate } = urlDateRangeFilters[field]
        const changed =
          currentMinDate !== minDateValue || currentMaxDate !== maxDateValue
        changes = {
          changed,
          currentMinDate,
          currentMaxDate,
        }
      }
      dateRangeFilters = update(dateRangeFilters, {
        [field]: { $merge: changes },
      })
    })

    const state = {
      date,
      active: !query.inactive,
      showDetails: query.details || 'contracts',
      search: query.search || '',
      filters: getFiltersFromUrl(query, employeeFields, null),
      salary: {
        show: showContracts || showSalary,
        showSalary: localStorage.contractTimeline === 'false',
        employeeId: salaryEmployeeId,
        contractTimelineDate,
      },
      timeline: {
        show: showTimeline || showMonthlyTimeline,
        employeeId: timelineEmployeeId,
        month: timelineMonth,
        editedBy,
        forEmployee,
        selectedCompany,
      },
      rangeFilters,
      dateRangeFilters,
    }

    let callback
    if (
      this.state.employees == null ||
      state.date.format(dateFormat) !== this.state.date.format(dateFormat) ||
      state.active !== this.state.active
    ) {
      callback = this.getEmployees
    } else {
      callback = this.filterEmployees
    }

    this.setState(state, callback)
  }

  getRangeFilters(query) {
    return employeeFields.reduce((acc, val) => {
      if (val.rangeFilter && query[val.name]) {
        const [currentMin, currentMax] = query[val.name].split('~')

        if (isFloat(currentMin) && isFloat(currentMax)) {
          acc[val.name] = {
            currentMin: parseFloat(currentMin),
            currentMax: parseFloat(currentMax),
          }
        }
      }
      return acc
    }, {})
  }

  getDateRangeFilters(query) {
    return employeeFields.reduce((acc, val) => {
      if (val.dateRangeFilter && query[val.name]) {
        const [currentMinDate, currentMaxDate] = query[val.name]
          .split('~')
          .map((val) =>
            moment.utc(val, dateFormat).isValid()
              ? moment.utc(val, dateFormat).toDate()
              : moment.utc().startOf('day').toDate(),
          )

        acc[val.name] = {
          currentMinDate,
          currentMaxDate,
        }
      }
      return acc
    }, {})
  }

  getToday() {
    return moment.utc().startOf('day')
  }

  getTodayFormatted() {
    return this.getToday().format(dateFormat)
  }

  getEmployees = () => {
    const { date, active, comparator } = this.state
    const options = {
      query: { date: date.format(dateFormat) },
    }

    apiRequest('employees', options)
      .then((response) => {
        this.setState(
          { employees: response.sort(comparator) },
          this.filterEmployees,
        )
      })
      .catch((err) => handleUncaughtError(err, this.context.addNotification))
  }

  getEmployeeById = async (employeeId) => {
    const { employees } = this.state

    if (!employees || employeeId == null) {
      return null
    }

    let employee = employees.find(({ id }) => id === employeeId)
    if (!employee) {
      try {
        employee = await apiRequest(`employees/${employeeId}`)
      } catch (err) {
        handleUncaughtError(err, this.context.addNotification)
      }
    }
    return employee
  }

  reloadEditorData = (date) => {
    if (!moment.isMoment(date)) {
      return
    }

    const { id } = this.state.editor.employee
    const options = {
      query: { date: date.format(dateFormat) },
    }

    apiRequest(`employees/${id}/contract`, options)
      .then((response) => {
        const { editor } = this.state
        const employee = { ...editor.employee, ...response }
        this.setState({ editor: { ...editor, employee, date } })
      })
      .catch((err) => handleUncaughtError(err, this.context.addNotification))
  }

  filterEmployees = () => {
    const { employees, search, showDetails, active } = this.state
    const filters = this.getFiltersWithDefaults()
    let { rangeFilters, dateRangeFilters } = this.state
    const formattedSearch = removeDiacritics(search).toLowerCase()

    // filters search bar
    let results = formattedSearch
      ? employees.filter(
          (e) =>
            removeDiacritics(getFullName(e))
              .toLowerCase()
              .includes(formattedSearch) ||
            removeDiacritics(e.vacuumId)
              .toLowerCase()
              .includes(formattedSearch) ||
            (e.jiraAccountId || '').toLowerCase().includes(formattedSearch),
        )
      : employees

    // filter by active status
    results = results.filter((employee) => employee.isActive === active)
    // extract subcontracts
    if (
      showDetails === 'contracts' ||
      (showDetails === 'personal' &&
        (!isEmpty(filters) || isChanged(rangeFilters))) ||
      (showDetails === 'employees' &&
        (!isEmpty(filters) || isChanged(rangeFilters)))
    ) {
      results = results.flatMap(extractContractAndSubcontracts)
    }

    // filter contracts only without subcontracts
    if (showDetails === 'withoutSubcontracts') {
      results = results
        .filter((e) => !e.subcontracts.length)
        .map((e) => ({
          ...e,
          isSubcontract: false,
          indexId: `${e.id}_${e.contractId}`,
        }))
    }

    // filter contracts only with subcontracts + extract subcontracts
    if (showDetails === 'withSubcontracts') {
      results = results
        .filter((e) => e.subcontracts.length)
        .flatMap(extractContractAndSubcontracts)
    }

    // filters select filters (Department, Salary Form, Legal Form, Billing Form, Salary Bonus)
    Object.entries(filters).forEach(([field, filter]) => {
      if (!filter.length) {
        return
      }

      results = results.filter(
        // filters use strings for all values so boolean values in employee
        // have to be converted to strings in comparison
        (employee) => filter.includes(String(employee[field])),
      )
    })

    // get min-max intervals for range filters based on select filters
    Object.entries(rangeFilters).forEach(([field, rangeFilter]) => {
      // console.log(field, rangeFilter)
      const changes = {}
      let minValue = Infinity
      let maxValue = -Infinity
      employees.forEach((employee) => {
        const value = parseFloat(employee[field]) || 0
        if (value > maxValue) {
          maxValue = value
        }
        if (value < minValue) {
          minValue = value
        }
      })

      if (minValue === Infinity) {
        minValue = 0
      }
      if (maxValue === -Infinity) {
        maxValue = 0
      }

      changes.minValue = minValue
      changes.maxValue = maxValue

      const { currentMin, currentMax, changed } = rangeFilter

      if (currentMin === undefined || !changed) {
        changes.currentMin = minValue
      }
      if (currentMax === undefined || !changed) {
        changes.currentMax = maxValue
      }

      rangeFilters = update(rangeFilters, { [field]: { $merge: changes } })

      // filter range filters
      if (!rangeFilter.changed) {
        return
      }

      results = results.filter((employee) => {
        let value = parseFloat(employee[field])
        if (isNaN(value)) {
          value = 0
        }
        return (
          value >= rangeFilter.currentMin && value <= rangeFilter.currentMax
        )
      })
    })

    // get min-max intervals for date range filters based on select filters
    Object.entries(dateRangeFilters).forEach(([field, dateRangeFilter]) => {
      const changes = {}
      let minDateValue
      let maxDateValue
      employees.forEach((employee) => {
        const dateValue = moment(employee[field]).toDate() || new Date()
        if (maxDateValue === undefined || dateValue > maxDateValue) {
          maxDateValue = dateValue
        }
        if (minDateValue === undefined || dateValue < minDateValue) {
          minDateValue = dateValue
        }
      })
      if (minDateValue === undefined) {
        minDateValue = new Date()
      }
      if (maxDateValue === undefined) {
        maxDateValue = new Date()
      }
      changes.minDateValue = minDateValue
      changes.maxDateValue = maxDateValue

      const { currentMinDate, currentMaxDate, changed } = dateRangeFilter

      if (currentMinDate === undefined || !changed) {
        changes.currentMinDate = minDateValue
      }
      if (currentMaxDate === undefined || !changed) {
        changes.currentMaxDate = maxDateValue
      }

      dateRangeFilters = update(dateRangeFilters, {
        [field]: { $merge: changes },
      })

      // filter date range filters
      if (!dateRangeFilter.changed) {
        return
      }

      results = results.filter((employee) => {
        const dateValue = employee[field]
        return (
          moment(dateValue) >= moment(dateRangeFilter.currentMinDate) &&
          moment(dateValue) <= moment(dateRangeFilter.currentMaxDate)
        )
      })
    })

    const { filteredEmployees } = this.state
    if (
      filteredEmployees &&
      filteredEmployees.length !== results.length &&
      this.scrollArea
    ) {
      this.scrollArea.scrollTop()
    }

    this.setState({
      filteredEmployees: results,
      rangeFilters,
      dateRangeFilters,
    })
  }

  changeDate = (date) => {
    if (
      moment.isMoment(date) &&
      date.format(dateFormat) !== this.state.date.format(dateFormat)
    ) {
      const stateChange = { date: date.startOf('day') }
      this.setState(stateChange, () => {
        this.getEmployees()
        this.updateUrl()
      })
    }
  }

  toggleActive = () => {
    const stateChange = { active: !this.state.active }
    this.setState(stateChange, () => {
      this.getEmployees()
      this.updateUrl()
    })
  }

  setShowDetails = (details) => {
    const stateChange = { showDetails: details }
    this.setState(stateChange, this.updateUrl)
  }

  handleFilter = ({ name, value }) => {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          [name]: isFieldDefaultValue(name, value) ? null : value,
        },
      },
      () => {
        this.filterEmployees()
        this.updateUrl()
      },
    )
  }

  handleRangeFilterReset = (name) => {
    const { minValue, maxValue } = this.state.rangeFilters[name]
    const changes = {
      currentMin: minValue,
      currentMax: maxValue,
      changed: false,
    }

    this.setState(
      (state) =>
        update(state, { rangeFilters: { [name]: { $merge: changes } } }),
      () => {
        this.filterEmployees()
        this.updateUrl()
      },
    )
  }

  handleRangeFilter = ({
    name,
    currentMin,
    currentMax,
    ignoreUpdate = false,
  }) => {
    const { minValue, maxValue } = this.state.rangeFilters[name]
    const changed = currentMin !== minValue || currentMax !== maxValue

    const cb = ignoreUpdate
      ? null
      : () => {
          this.filterEmployees()
          this.updateUrl()
        }

    const changes = { minValue, maxValue, currentMin, currentMax, changed }

    this.setState(
      (state) =>
        update(state, { rangeFilters: { [name]: { $merge: changes } } }),
      cb,
    )
  }

  handleDateRangeFilterReset = (name) => {
    const { minDateValue, maxDateValue } = this.state.dateRangeFilters[name]
    const changes = {
      currentMinDate: minDateValue,
      currentMaxDate: maxDateValue,
      changed: false,
    }

    this.setState(
      (state) =>
        update(state, { dateRangeFilters: { [name]: { $merge: changes } } }),
      () => {
        this.filterEmployees()
        this.updateUrl()
      },
    )
  }

  handleDateRangeFilter = ({
    name,
    currentMinDate,
    currentMaxDate,
    ignoreUpdate = false,
  }) => {
    const { minDateValue, maxDateValue } = this.state.dateRangeFilters[name]
    const changed =
      currentMinDate !== minDateValue || currentMaxDate !== maxDateValue

    const cb = ignoreUpdate
      ? null
      : () => {
          this.filterEmployees()
          this.updateUrl()
        }

    const changes = {
      minDateValue,
      maxDateValue,
      currentMinDate,
      currentMaxDate,
      changed,
    }

    this.setState(
      (state) =>
        update(state, { dateRangeFilters: { [name]: { $merge: changes } } }),
      cb,
    )
  }

  handleTimelineFilter = (month, editedBy, forEmployee, selectedCompany) => {
    this.setState(
      (state) =>
        update(state, {
          timeline: {
            $merge: { month, editedBy, forEmployee, selectedCompany },
          },
        }),
      this.updateUrl,
    )
  }

  handleSearch = ({ target: { value } }) => {
    this.setState({ search: value }, () => {
      this.filterEmployees()
      this.updateUrl()
    })
  }

  clearSearch = () => {
    this.setState({ search: '' }, () => {
      this.filterEmployees()
      this.updateUrl()
    })
  }

  handleContractTimelineSelect = (date) => {
    const { readAccess } = getConfig()
    this.setState(
      (state) =>
        update(state, { salary: { $merge: { contractTimelineDate: date } } }),
      !readAccess ? this.updateUrlEmployee : this.updateUrl,
    )
  }

  showBulkImportModal = () => {
    this.setState({ showBulkImportModal: true })
  }

  hideBulkImportModal = () => {
    this.setState({ showBulkImportModal: false })
  }

  showBulkUpdateModal() {
    this.setState({ showBulkUpdateModal: true })
  }

  hideBulkUpdateModal() {
    this.setState({ showBulkUpdateModal: false })
  }

  showTimeline = (employee = null) => {
    this.setState(
      {
        timeline: {
          show: true,
          employeeId: get(employee, 'id'),
          month: null,
          editedBy: null,
          forEmployee: null,
          selectedCompany: null,
        },
      },
      this.updateUrl,
    )
  }

  hideTimeline = () => {
    this.setState(
      {
        timeline: {
          show: false,
          employeeId: null,
          month: null,
          editedBy: null,
          forEmployee: null,
          selectedCompany: null,
        },
      },
      this.updateUrl,
    )
  }

  showHistoricalData = () => {
    this.setState({ showHistoricalData: true })
  }

  hideHistoricalData = () => {
    this.setState({ showHistoricalData: false })
  }

  showEditor = () => {
    this.setState({
      editor: {
        show: true,
        employee: {},
        updating: false,
        date: this.state.date,
      },
    })
  }

  showPrefilledEditor = (employee) => {
    this.setState({
      editor: { show: true, employee, updating: true, date: this.state.date },
    })
  }

  hideEditor = () => {
    this.setState({ editor: { show: false, employee: {}, updating: false } })
  }

  showManualShareEditor = (employee) => {
    this.setState({
      manualShareEditor: { show: true, employeeId: get(employee, 'id', null) },
    })
  }

  hideManualShareEditor = () => {
    this.setState({ manualShareEditor: { show: false, employeeId: null } })
  }

  handleEmployeeSave = (
    redirectToCreateNewCompany = false,
    employeeId = null,
    contractId = null,
  ) => {
    this.hideEditor()
    if (
      redirectToCreateNewCompany &&
      employeeId !== null &&
      contractId !== null
    ) {
      this.props.history.push(
        `/companies?employeeId=${employeeId}&contractId=${contractId}`,
      )
    } else {
      this.getEmployees()
    }
  }

  showSalaryModal = (employee) => {
    this.setState(
      {
        salary: {
          show: true,
          employeeId: employee.id,
          contractTimelineDate: null,
        },
      },
      this.updateUrl,
    )
  }

  hideSalaryModal = () => {
    this.setState(
      {
        salary: {
          show: false,
        },
      },
      this.updateUrl,
    )
  }

  generateExport = ({ value }) => {
    const { filteredEmployees, date } = this.state

    if (value === 'monthly' || value === 'yearly') {
      const fields = {
        employeeFields: employeeFieldsMonthlyExportable,
        companyFields: companyFieldsExportable,
      }
      if (value === 'monthly') {
        getMonthlyExportXLSX(date, this.context.addNotification, fields)
      } else {
        getYearlyExportXLSX(date, this.context.addNotification, fields)
      }
    } else {
      const dateFormatted = moment.utc(date).format(dateFormat)
      const fields = {
        employeeFields: employeeFieldsExportable,
        companyFields: companyFieldsForEmployeeExport,
      }
      if (value === 'csv') {
        generateCSV(filteredEmployees, dateFormatted, fields)
      } else {
        generateXLSX(filteredEmployees, dateFormatted, fields)
      }
    }
  }

  setScrollAreaRef = (ref) => {
    if (ref) {
      this.scrollArea = ref.scrollArea
    }
  }

  setComparator = (key) => {
    const { filteredEmployees, employees } = this.state
    this.setState({
      comparator: comparators[key],
      employees: employees.sort(comparators[key]),
      filteredEmployees: filteredEmployees.sort(comparators[key]),
    })
  }

  renderEmployeeTable() {
    const { showDetails, date } = this.state
    const filtersWithDefaults = this.getFiltersWithDefaults()

    const employeeFields = getEmployeeFields(showDetails)

    return (
      <Col xs={12}>
        <div className={styles.employees}>
          <div className={styles.employeesHeader}>
            <div className={styles.employeesRow}>
              <div className={styles.statusBar} />
              {employeeFields.map(
                ({
                  name,
                  label,
                  options,
                  filter,
                  rangeFilter,
                  dateRangeFilter,
                }) => {
                  const dropdownStyles = classnames([
                    styles.employeesHeaderCell,
                    styles[`box-${name}`],
                  ])
                  if (filter) {
                    const value = this.state.filters[name]
                    const valueWithDefault = filtersWithDefaults[name]

                    return (
                      <ColumnDropdown
                        label={label}
                        name={name}
                        key={name}
                        value={valueWithDefault}
                        isValueDefault={value == null}
                        options={options}
                        filterType={filter}
                        className={dropdownStyles}
                        onClick={this.handleFilter}
                      />
                    )
                  } else if (rangeFilter) {
                    const {
                      minValue,
                      maxValue,
                      currentMin,
                      currentMax,
                      changed,
                    } = this.state.rangeFilters[name]

                    return (
                      <ColumnRangeDropdown
                        label={label}
                        name={name}
                        key={name}
                        className={dropdownStyles}
                        minValue={minValue}
                        maxValue={maxValue}
                        currentMin={currentMin}
                        currentMax={currentMax}
                        changed={changed}
                        onReset={this.handleRangeFilterReset}
                        onChange={this.handleRangeFilter}
                      />
                    )
                  } else if (dateRangeFilter) {
                    const { currentMinDate, currentMaxDate, changed } =
                      this.state.dateRangeFilters[name]

                    return (
                      <ColumnDateRangeDropdown
                        label={label}
                        name={name}
                        key={name}
                        className={dropdownStyles}
                        currentMinDate={currentMinDate}
                        currentMaxDate={currentMaxDate}
                        changed={changed}
                        onReset={this.handleDateRangeFilterReset}
                        onChange={this.handleDateRangeFilter}
                      />
                    )
                  }
                  return (
                    <div key={name} className={dropdownStyles}>
                      {label}
                    </div>
                  )
                },
              )}
              <ColumnDropdown
                download
                name="download"
                className={classnames([
                  styles.employeesHeaderCell,
                  styles.employeesHeaderButtons,
                ])}
                options={exportOptions}
                onClick={this.generateExport}
              />
            </div>
          </div>

          <ScrollArea
            className={styles.employeesBody}
            horizontal={false}
            ref={this.setScrollAreaRef}
            verticalContainerClassNameVertical="vertical"
          >
            {this.state.filteredEmployees.map((employee, index) => (
              <Employee
                key={employee.indexId}
                rowNumber={index + 1}
                employee={employee}
                employeeFields={employeeFields}
                onClick={this.showSalaryModal}
                showTimeline={this.showTimeline}
                showPrefilledEditor={this.showPrefilledEditor}
                showDetails={showDetails}
                showManualShareEditor={this.showManualShareEditor}
                date={date}
              />
            ))}
          </ScrollArea>
        </div>
      </Col>
    )
  }

  render() {
    const {
      employees,
      date,
      active,
      editor,
      search,
      showBulkImportModal,
      showBulkUpdateModal,
      timeline,
      showHistoricalData,
      salary,
      manualShareEditor,
      showDetails,
    } = this.state
    const { readAccess, writeAccess } = getConfig()
    const firstDayOfMonthDate = moment(date).date(1)

    if (!employees) {
      return <Loading />
    }

    return (
      <Grid>
        {readAccess && (
          <TableTitle name="Employees" onClick={this.showEditor} />
        )}
        {readAccess && (
          <Row className={styles.filters}>
            <Col lg={2} md={4} sm={5} xs={6}>
              <InputGroup>
                <FormControl
                  type="text"
                  className={search && styles.highlighted}
                  value={search !== undefined ? search : ''}
                  placeholder="Search by Vacuum ID or Name"
                  onChange={this.handleSearch}
                  onKeyUp={(e) => e.key === 'Escape' && this.clearSearch()}
                />
                <InputGroup.Button>
                  <Button
                    title="Clear"
                    disabled={!search}
                    className={search && styles.highlighted}
                    onClick={this.clearSearch}
                  >
                    <Glyphicon glyph="remove" />
                    &zwnj;
                  </Button>
                </InputGroup.Button>
              </InputGroup>
            </Col>
            <Col lg={2} md={3} sm={4} xs={6}>
              <DateTimeToday
                name="date"
                value={date}
                className={
                  date.format(dateFormat) !== this.getTodayFormatted() &&
                  styles.highlighted
                }
                onChange={this.changeDate}
                timeFormat={false}
                closeOnSelect
              />
            </Col>
            <Col
              lg={3}
              lgPush={5}
              md={4}
              sm={3}
              smPush={0}
              xs={4}
              xsPush={8}
              className={styles.colRight}
            >
              {writeAccess && (
                <Button onClick={this.showBulkImportModal}>Bulk import</Button>
              )}
              {writeAccess && (
                <Button onClick={() => this.showBulkUpdateModal()}>
                  Bulk update
                </Button>
              )}
              <Button
                onClick={(e) => this.showTimeline()}
                data-test="openTimeline"
              >
                Monthly Timeline
              </Button>
              {writeAccess && (
                <Button onClick={this.showHistoricalData}>
                  Historical Data
                </Button>
              )}
            </Col>
            <Clearfix visibleSmBlock visibleMdBlock />
            <Col lg={2} lgPull={3} sm={4} smPull={0} xs={2} xsPull={4}>
              <ButtonGroup>
                <Button
                  className={!active && styles.highlighted}
                  onClick={this.toggleActive}
                >
                  {active ? statuses.active.label : statuses.inactive.label}
                </Button>
                <DropdownButton id="employeesOrderBy" title="Order By">
                  <MenuItem
                    onSelect={() => this.setComparator('nameAscending')}
                  >
                    Name, Ascending
                  </MenuItem>
                  <MenuItem
                    onSelect={() => this.setComparator('nameDescending')}
                  >
                    Name, Descending
                  </MenuItem>
                  <MenuItem
                    onSelect={() => this.setComparator('startAscending')}
                  >
                    Starting Date, Ascending
                  </MenuItem>
                  <MenuItem
                    onSelect={() => this.setComparator('startDescending')}
                  >
                    Starting Date, Descending
                  </MenuItem>
                </DropdownButton>
              </ButtonGroup>
            </Col>
            <Col
              lg={3}
              lgPull={3}
              sm={8}
              smPull={0}
              xs={6}
              xsPull={4}
              className={styles.colRight}
            >
              <ButtonGroup>
                <DropdownButton
                  id="employeesContractBtn"
                  title="Contracts"
                  className={
                    showDetails !== 'personal' &&
                    showDetails !== 'employees' &&
                    styles.highlighted
                  }
                  data-test="contractsButton"
                >
                  <MenuItem onSelect={() => this.setShowDetails('contracts')}>
                    All contracts
                  </MenuItem>
                  <MenuItem
                    onSelect={() => this.setShowDetails('withSubcontracts')}
                  >
                    Contracts with subcontracts
                  </MenuItem>
                  <MenuItem
                    onSelect={() => this.setShowDetails('withoutSubcontracts')}
                  >
                    Contracts without subcontracts
                  </MenuItem>
                </DropdownButton>
                <Button
                  className={showDetails === 'personal' && styles.highlighted}
                  onClick={() => this.setShowDetails('personal')}
                  data-test="personalButton"
                >
                  Personal
                </Button>
                <Button
                  className={showDetails === 'employees' && styles.highlighted}
                  onClick={() => this.setShowDetails('employees')}
                  data-test="employeesButton"
                >
                  Employees
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        )}
        {!readAccess ? (
          <Row>
            <SalaryContractsInfo
              modal={false}
              show
              employeeId={employees[0].id}
              showSalary={salary.showSalary}
              getEmployeeById={this.getEmployeeById}
              updateUrl={this.updateUrlEmployee}
              onContractSelect={this.handleContractTimelineSelect}
              selectedContractDate={salary.contractTimelineDate}
              date={date}
            />
          </Row>
        ) : (
          <Row>
            {this.renderEmployeeTable()}
            <EmployeeEditor
              show={editor.show}
              date={editor.date}
              updating={editor.updating}
              employee={editor.employee}
              employees={employees}
              reloadEditorData={this.reloadEditorData}
              onCancel={this.hideEditor}
              onSave={this.handleEmployeeSave}
            />
            <BulkImportModal
              show={showBulkImportModal}
              onCancel={this.hideBulkImportModal}
            />
            <BulkUpdateModal
              show={showBulkUpdateModal}
              queryDate={date.format(dateFormat)}
              onCancel={() => this.hideBulkUpdateModal()}
              onSave={this.handleEmployeeSave}
            />
            <Timeline
              show={timeline.show}
              month={timeline.month}
              employeeId={timeline.employeeId}
              editedBy={timeline.editedBy}
              forEmployee={timeline.forEmployee}
              selectedCompany={timeline.selectedCompany}
              date={date}
              getEmployeeById={this.getEmployeeById}
              handleTimelineFilter={this.handleTimelineFilter}
              onCancel={this.hideTimeline}
            />
            <HistoricalData
              show={showHistoricalData}
              onCancel={this.hideHistoricalData}
              vlCompanies={vlCompanies}
            />
            <SalaryContractsInfo
              modal
              show={salary.show}
              employeeId={salary.employeeId}
              showSalary={salary.showSalary}
              getEmployeeById={this.getEmployeeById}
              updateUrl={this.updateUrl}
              onChange={this.getEmployees}
              onCancel={this.hideSalaryModal}
              onContractSelect={this.handleContractTimelineSelect}
              selectedContractDate={salary.contractTimelineDate}
              date={date}
            />
            <ManualShareEditor
              show={manualShareEditor.show}
              date={firstDayOfMonthDate}
              onCancel={this.hideManualShareEditor}
              onChange={this.getEmployees}
              employeeId={manualShareEditor.employeeId}
              getEmployeeById={this.getEmployeeById}
            />
          </Row>
        )}
      </Grid>
    )
  }
}
